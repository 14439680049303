import React from 'react';
import styles from './AppAdminHomeFilter.module.scss';
import AppSelect from '../../AppSelect/AppSelect';
import AppButton from '../../AppButton/AppButton';

export default function AppAdminHomeFilter() {
  return (
    <div className={styles.AppAdminHomeFilter}>
      <div className={styles.AppAdminHomeFilter__select}>
        <AppSelect placeholder="აუქციონი" />
        <AppSelect placeholder="ლოკაცია" />
        <AppButton large label={'შეცვლა'} leftIcon>
          <SaveIcon />
        </AppButton>
      </div>
    </div>
  );
}

const SaveIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21V13H7V21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3V8H15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from 'react';
import AppEmployeeProfile from '../../components/Employee/AppEmployeeProfile';

export default function EmployeeProfilePage() {
  return (
    <div style={{ paddingBottom: 40 }}>
      <AppEmployeeProfile />
    </div>
  );
}

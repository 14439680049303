import React from 'react';
import AppAdminProfile from '../components/Admin/AppAdminProfile/AppAdminProfile';

export default function AdminProfilePage() {
  return (
    <div style={{ paddingBottom: 40 }}>
      <AppAdminProfile />
    </div>
  );
}

import React from 'react';
import AppDealerProfile from '../../components/Dealer/AppDealerProfile';

export default function DealerProfilePage() {
  return (
    <div style={{ paddingBottom: 40 }}>
      <AppDealerProfile />
    </div>
  );
}
